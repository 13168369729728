<template>
  <div class="popup-container" :class="{'is-active': modelValue}">
    <div class="popup" id="popup-mypage-z11" style="flex:0 0 1060px">
      <div class="popup-inner">
        <div class="popup-actions">
          <button type="button" class="kb-btn kb-btn-primary kb-btn-sm rounded-lg" v-print="printObj" @click="printThis">
            <i class="icon-print-doc"></i>
            <span class="text">이력인쇄</span>
          </button>
        </div>
        <div class="popup-content" id="printMe" ref="printRef">
          <table style="width:1060px;">
            <tr>
              <td>
                <ul style="text-align: right">
                  <li style="color: #757575">
                    출력직원 : {{ session.deptNm }} / {{ session.jbgdNm }} {{ session.jbpsNm }} /
                    {{ session.lrnerNm }}({{ session.lrnerId }})
                    / 출력일시 : {{ timestampToDateFormat(new Date().getTime(), 'yyyy.MM.dd hh:mm:ss') }}
                  </li>
                </ul>
              </td>
            </tr>
          </table>
          <table style="width:1060px;margin-top: 10px;margin-bottom: 15px;">
            <tr>
              <th style="text-align: left">
                <span class="fs-5">직원정보 : {{ session.deptNm }} / {{ session.jbgdNm }} {{ session.jbpsNm }} /
                    {{ session.lrnerNm }}({{ session.lrnerId }})</span>
              </th>
            </tr>
          </table>
          <table style="width:1060px;margin-top: 10px;">
            <tr>
              <td>
                <header class="section-header" style="margin-bottom: 5px;">
                  <h3 class="td-text">연수이력</h3>
                </header>
              </td>
            </tr>
          </table>
          <table style="width:1060px;">
            <colgroup>
              <col style="width:170px">
              <col style="width:auto">
              <col style="width:210px">
              <col style="width:80px">
              <col style="width:50px">
            </colgroup>
            <thead>
            <tr style="border-top:1px solid var(--kb-border-color);text-align: center; height: 40px;background-color: var(--kb-background-brown);">
              <th style="padding-left: 10px"><span class="th-title">주관처</span></th>
              <th><span class="th-title">과정명</span></th>
              <th><span class="th-title">연수기간</span></th>
              <th><span class="th-title">점수</span></th>
              <th><span class="th-title">결과</span></th>
            </tr>
            </thead>
            <tbody>
            <template v-if="lrnsBodies.length > 0">
              <template v-for="(item, index) in lrnsBodies" :key="`lrns-${index}`">
                <tr style="border-top:1px solid var(--kb-border-color);height: 40px;border-bottom:1px solid var(--kb-border-color);">
                  <td style="padding-left: 10px"><span class="td-text">{{ item.prvdrNm ? item.prvdrNm : '-' }}</span>
                  </td>
                  <td style="padding-left: 10px"><strong class="td-text">{{ item.crseNm }}</strong></td>
                  <td style="text-align: center"><span class="td-text">
                  {{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~
                  {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span></td>
                  <td style="text-align: center"><span class="td-text">{{ getLearningScore(item) }}</span></td>
                  <td style="text-align: center">
                    <div class="item-status">
                    <span class="td-text">
                      {{ getLearnStatusText(getLearningStatus(item)) }}
                    </span>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr style="border-top:1px solid var(--kb-border-color);height: 40px;border-bottom:1px solid var(--kb-border-color);">
                <td colspan="5" style="text-align: center">등록된 정보가 없습니다.</td>
              </tr>
            </template>
            </tbody>
          </table>
          <table style="width:1060px;margin-top: 40px">
            <tr>
              <td>
                <header class="section-header" style="margin-bottom: 5px;">
                  <h3 class="td-text">자격증</h3>
                </header>
              </td>
            </tr>
          </table>
          <table style="width:1060px;">
            <colgroup>
              <col style="width:auto">
              <col style="width:170px">
              <col style="width:210px">
              <col style="width:80px">
              <col style="width:100px">
            </colgroup>
            <thead>
            <tr style="border-top:1px solid var(--kb-border-color);text-align: center; height: 40px;background-color: var(--kb-background-brown);">
              <th style="padding-left: 10px"><span class="th-title">자격증명</span></th>
              <th><span class="th-title">주관처</span></th>
              <th><span class="th-title">자격적용일</span></th>
              <th><span class="th-title">등급</span></th>
              <th><span class="th-title">지원금액</span></th>
            </tr>
            </thead>
            <tbody>
            <template v-if="lcnsBodies.length > 0">
              <template v-for="(item, index) in lcnsBodies" :key="`lcns-${index}`">
                <tr style="border-top:1px solid var(--kb-border-color);height: 40px;border-bottom:1px solid var(--kb-border-color);">
                  <td style="padding-left: 10px"><span class="td-text">{{ item.lcsnNm }}</span></td>
                  <td style="padding-left: 10px"><strong class="td-text">{{ item.pblcnInstNm }}</strong></td>
                  <td style="text-align: center"><span class="td-text">{{ item.passYmd }}</span></td>
                  <td style="text-align: center"><span class="td-text">{{ item.grd1Cd ? item.grd1Cd : '-' }}</span></td>
                  <td style="text-align: center"><span class="td-text">{{
                      item.sprtAmt > 0 ?
                          numberComma(item.sprtAmt) :
                          '-'
                    }}</span></td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr style="border-top:1px solid var(--kb-border-color);height: 40px;border-bottom:1px solid var(--kb-border-color);">
                <td colspan="5" style="text-align: center">등록된 정보가 없습니다.</td>
              </tr>
            </template>
            </tbody>
          </table>
          <table style="width:1060px;margin-top: 10px">
            <tr>
              <td style="text-align: right">
                <img src="@/assets/lxp/images/common/kb_logo.svg" style="width: 82px; height: 32px;">
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {numberComma, timestampToDateFormat} from '@/assets/js/util';

export default {
  name: 'MyPagePrintModal',
  props: {
    effected: Number,
    bodies: Array,
    modelValue: Boolean,
  },
  components: {},
  setup(props, {emit}) {

    const store = useStore();

    const printRef = ref(null);
    const printLoading = ref(false);

    const session = computed(() => store.state.auth.session || {});
    const orgKey = ref('');
    const currentDate = new Date();
    const currentTs = currentDate.getTime();
    const lrnsBodies = computed(() => props.bodies.filter(x => x.target === 'lrns'));
    const lcnsBodies = computed(() => props.bodies.filter(x => x.target === 'lcns'));

    const printObj = ref({
      id: 'printMe',
      beforeOpenCallback(vue) {
        vue.printLoading = true;
        // console.log('beforeOpenCallback')
      },
      openCallback(vue) {
        vue.printLoading = false;
        // console.log('openCallback')
      },
    });

    const printThis = () => {
      // console.log(printRef)
      window.onbeforeprint = printRef.value;
    };

    const finishOptions = [
      {value: 1, display: '수료'},
      {value: 2, display: '미수료'},
      {value: 3, display: '결과처리중'},
      {value: 4, display: '학습진행중'},
      {value: 5, display: '-'},
    ];

    const getLearningStatus = (item) => {
      // 학습 시작전
      if (currentTs < item.bgngDt) {
        return 5;
      } else if (currentTs >= item.bgngDt && currentTs <= item.endDt) {
        return 4;
      } else if (currentTs > item.endDt) {
        if (item.fnshYn === 'Y') {
          return 1;
        } else if (item.fnshYn === 'N') {
          return 2;
        }
      }
      return 3;

    };

    const getLearnStatusText = (status) => {
      const target = finishOptions.find(x => x.value === status);
      if (target) {
        return target.display;
      }
      return '-';
    };

    const getLearningScore = (item) => {
      return item.getScore !== null && item.getScore !== undefined ? item.getScore : '-';
    };

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    watch(() => props.effected, () => {
      // getKey();
    });

    onMounted(() => {
      printRef.value = printRef.value.target;
    });

    return {
      printThis,
      printRef,
      printLoading,
      printObj,
      session,
      orgKey,
      closeModal,
      numberComma,
      timestampToDateFormat,
      getLearningScore,
      getLearningStatus,
      getLearnStatusText,
      lrnsBodies,
      lcnsBodies,
    };
  },
};
</script>

<style scoped>

.hr-info-printCont .proof {
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: -0.02em;
  padding-top: 2%;
  padding-bottom: 4%;
}

.hr-info-printCont .bottom-cont {
  padding-bottom: 4%;
}

.hr-info-printCont .bottom-cont b:first-child {
  padding-bottom: 2%;
}

.hr-info-printCont .bottom-cont b {
  padding-bottom: 2%;
}

</style>