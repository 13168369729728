<template>
  <div class="mypage-my">
    <div class="my-avatar">
<!--      <i class="icon-grade-5"></i>-->
      <div class="image">
        <ProfileImg
            :target="session"
        />
      </div>
    </div>
    <div class="my-title"><h3 class="title">{{ session.lrnerNm }}님, 반가워요!</h3></div>
    <div class="my-links">
      <a href="javascript:" class="link" @click="changePassword"><span class="text">비밀번호 변경</span><i class="icon-arrow"></i></a>
      <router-link :to="{name: 'MyProfile'}" class="link"><span class="text">내 정보 수정</span><i class="icon-arrow"></i></router-link>
    </div>
    <MyPasswordChangeModal v-model="passwordModal" :lrner-id="session.lrnerId" />
  </div>
</template>

<script>

import {computed, ref} from 'vue';
import ProfileImg from '@/components/common/ProfileImg';
import {useStore} from 'vuex';
import MyPasswordChangeModal from "@/components/my/MyPasswordChangeModal";

export default {
  name: "MyPageProfile",
  components: {MyPasswordChangeModal, ProfileImg},

  setup() {
    const store = useStore();
    const session = computed(() => store.state.auth.session);

    const passwordModal = ref(false);

    const changePassword = () => {
      passwordModal.value = true;
    }

    return {
      session,
      passwordModal,
      changePassword
    }
  }
}
</script>
