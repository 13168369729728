<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container" :class="{'is-active' : modelValue}">
    <div class="popup" id="popup-mypage-password">
      <div class="popup-inner">
        <div>
          <header class="popup-header">
            <h3 class="title">글로벌 어학평가</h3>
          </header>
          <div class="popup-content">
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">Information</h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-bordered-v2 member-tablelist">
                  <table>
                    <colgroup>
                      <col style="width:30%">
                      <col style="width:70%">
                    </colgroup>
                    <tbody>
                      <tr>
                        <th class="align-top global-background"><span class="th-title">성명/직원번호</span></th>
                        <td><strong class="td-text">{{session.lrnerNm}} / {{session.lrnerId}}</strong></td>
                      </tr>
                      <tr>
                        <th class="align-top global-background"><span class="th-title">응시일자</span></th>
                        <td><strong class="td-text">{{item.examDd ? ymdStrToFormat(item.examDd, '.') : '-'}}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">Test Result</h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-bordered-v2 member-tablelist">
                  <table>
                    <colgroup>
                      <col style="width:10%">
                      <col style="width:10%">
                      <col style="width:30%">
                      <col style="width:10%">
                      <col style="width:10%">
                      <col style="width:30%">
                    </colgroup>
                    <tbody>
                      <tr>
                        <th colspan="6" class="align-top text-center global-background"><span class="th-title">Result</span></th>
                      </tr>
                      <tr>
                        <th colspan="3" class="align-top text-center global-background"><span class="th-title">Speaking</span></th>
                        <th colspan="3" class="align-top text-center global-background"><span class="th-title">Writing</span></th>
                      </tr>
                      <tr>
                        <th class="align-top text-center global-background"><span class="th-title">Grade</span></th>
                        <th class="align-top text-center global-background"><span class="th-title">Score</span></th>
                        <th class="align-top text-center global-background"><span class="th-title">Comment</span></th>
                        <th class="align-top text-center global-background"><span class="th-title">Grade</span></th>
                        <th class="align-top text-center global-background"><span class="th-title">Score</span></th>
                        <th class="align-top text-center global-background"><span class="th-title">Comment</span></th>
                      </tr>
                      <tr>
                        <td class="align-top text-center"><span class="th-title">{{item.convGrd != null ? item.convGrd+'등급' : '-'}}</span></td>
                        <td class="align-top text-center"><span class="th-title">{{item.convScore}}점</span></td>
                        <td class="align-top"><span class="th-title">{{item.convCmnt ? item.convCmnt : '-'}}</span></td>
                        <td class="align-top text-center"><span class="th-title">{{item.compoGrd != null ? item.compoGrd+'등급' : '-'}}</span></td>
                        <td class="align-top text-center"><span class="th-title">{{item.compoScore}}점</span></td>
                        <td class="align-top"><span class="th-title">{{item.compoCmnt ? item.compoCmnt : '-'}}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">Speaking test Details</h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-bordered-v2 member-tablelist">
                  <table>
                    <colgroup>
                      <col style="width:30%">
                      <col style="width:70%">
                    </colgroup>
                    <tbody>
                      <tr>
                        <th class="align-top global-background"><span class="th-title">Criteria</span></th>
                        <th class="align-top global-background"><span class="th-title">Comments</span></th>
                      </tr>
                      <tr>
                        <th class="align-top global-background"><span class="th-title">Vocabulary</span></th>
                        <td><strong class="td-text">{{item.vocCmnt ? item.vocCmnt : '-'}}</strong></td>
                      </tr>
                      <tr>
                        <th class="align-top global-background"><span class="th-title">Pronunciation</span></th>
                        <td><strong class="td-text">{{item.proncCmnt ? item.proncCmnt : '-'}}</strong></td>
                      </tr>
                      <tr>
                        <th class="align-top global-background"><span class="th-title">Grammar</span></th>
                        <td><strong class="td-text">{{item.grrCmnt ? item.grrCmnt : '-'}}</strong></td>
                      </tr>
                      <tr>
                        <th class="align-top global-background"><span class="th-title">Fluency</span></th>
                        <td><strong class="td-text">{{item.fludCmnt ? item.fludCmnt : '-'}}</strong></td>
                      </tr>
                      <tr>
                        <th class="align-top global-background"><span class="th-title">Comprehension</span></th>
                        <td><strong class="td-text">{{item.intpnCmnt ? item.intpnCmnt : '-'}}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">General Review</h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-bordered-v2 member-tablelist">
                  <table>
                    <colgroup>
                      <col style="width:30%">
                      <col style="width:70%">
                    </colgroup>
                    <tbody>
                    <tr>
                      <th colspan="2" class="align-top text-center global-background"><span class="th-title">Strong Points and Weak Points</span></th>
                    </tr>
                    <tr>
                      <th class="align-top global-background"><span class="th-title">Strong Points</span></th>
                      <td><strong class="td-text">{{item.strDesc ? item.strDesc : '-'}}</strong></td>
                    </tr>
                    <tr>
                      <th class="align-top global-background"><span class="th-title">Weak Points</span></th>
                      <td><strong class="td-text">{{item.weakDesc ? item.weakDesc : '-'}}</strong></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">Interviewer</h4>
              </header>
              <div class="section-content">
                <div class="kb-table kb-table-bordered-v2 member-tablelist">
                  <table>
                    <colgroup>
                      <col style="width:25%">
                      <col style="width:25%">
                      <col style="width:25%">
                      <col style="width:25%">
                    </colgroup>
                    <tbody>
                    <tr>
                      <th class="align-top global-background"><span class="th-title">1st Interviewer</span></th>
                      <td><strong class="td-text">{{item.frstInterViewrNm ? item.frstInterViewrNm : '-'}}</strong></td>
                      <th class="align-top global-background"><span class="th-title">Date</span></th>
                      <td><strong class="td-text">{{item.evlDt ? ymdStrToFormat(item.evlDt, '.') : '-'}}</strong></td>
                    </tr>
                    <tr>
                      <th class="align-top global-background"><span class="th-title">2nd Interviewer</span></th>
                      <td><strong class="td-text">{{item.sendInterViewrNm ? item.sendInterViewrNm : '-'}}</strong></td>
                      <th class="align-top global-background"><span class="th-title">Remarks</span></th>
                      <td><strong class="td-text">-</strong></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </article>
          </div>
        </div>
        <!-- popup > popup-close -->
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">닫기</i></button>
        </div>
      </div>
    <!-- //popup -->
    </div>
  </div>
  <!-- //[begin::popup-container] -->
</template>

<script>

import {ymdStrToFormat} from "@/assets/js/util";
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "MyGlobalModal",
  props: {
    modelValue: Boolean,
    key: Number,
    item: Array
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();

    const session = computed(() => store.state.auth.session);

    const closeModal = () => {
      emit('update:modelValue', false);
    }

    return {
      session,
      closeModal,
      ymdStrToFormat
    }
  }
  
}
</script>
<style scoped>
.global-background {background-color:Var(--kb-background-brown);}
</style>