<template>
  <MyPageMileageStatus
      :learns="learns"
      :licenses="licenses"
      :hrdCloudMlg="hrdCloudMlg"
      :current-year="currentYear"
  />
  <MyPageMileageLearn
      :items="learns"
      :current-year="currentYear"
  />
  <MyPageMileageLicense
      :items="licenses"
      :current-year="currentYear"
  />

</template>

<script>
import MyPageMileageStatus from '@/components/mypage/mileage/MyPageMileageStatus';
import MyPageMileageLearn from '@/components/mypage/mileage/MyPageMileageLearn';
import MyPageMileageLicense from '@/components/mypage/mileage/MyPageMileageLicense';

export default {
  name: "MyPageMileage",
  components: {MyPageMileageLicense, MyPageMileageLearn, MyPageMileageStatus},
  props: {
    learns: Array,
    licenses: Array,
    hrdCloudMlg: Number
  },
  setup() {
    const currentYear = new Date().getFullYear();

    return {
      currentYear
    }

  }
}
</script>
