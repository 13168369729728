<template>
  <div class="kb-badge" @click="handleClick">
    <div class="badge-icon">
      <div v-if="isNew" class="badge">
        <span class="badge-text">NEW</span>
      </div>
      <SSLBadge
          v-if="division === 'ssl'"
          :badgeTyCdDcd="badgeTyCdDcd"
          :is-active="isActive"
          :hidden-yn="hiddenYn"
      />
      <HrdCloudBadge
          v-else-if="division === 'hrdcloud'"
          :badgeTyCdDcd="badgeTyCdDcd"
          :is-active="isActive"
          :hidden-yn="hiddenYn"
      />
    </div>
    <template v-if="badgeMeta">
      <div class="badge-content">
        <strong class="title" style="white-space: normal; word-break:break-all" :class="{ 'kb-badge-title' : isMobile }">{{ badgeMeta.badgeName }}</strong>
      </div>
      <div v-if="hiddenYn!=='Y' || isActive" class="badge-popover">
        <span class="popover-title">{{ badgeMeta.badgeName }}</span>
        <span class="popover-text">{{ badgeMeta.badgeDesc }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import SSLBadge from '@/components/ssl/badge/SSLBadge';
import {computed} from 'vue';
import HrdCloudBadge from '@/components/history/hrdcloud/HrdCloudBadge';
import {useStore} from 'vuex';

export default {
  name: 'IntegratedBadge',
  components: {HrdCloudBadge, SSLBadge},
  props: {
    division: String,
    badges: Object,
    badgeTyCdDcd: String,
    isActive: Boolean,
    isNew: Boolean,
    hiddenYn: String,
    year: {
      required: false,
      default() {
        return new Date().getFullYear().toString();
      },
    },
    clickFunc: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const badgeMeta = computed(() => {
      const targetBadge = {
        ...props,
        badgeName: null,
        badgeDesc: null,
      };

      if (props.hiddenYn === 'Y' && !props.isActive) {
        targetBadge.badgeName = props.badges.hiddenBadgeTyNm;
        targetBadge.badgeDesc = props.badges.hiddenBadgeCn;
      } else {
        targetBadge.badgeName = props.badges.badgeTyNm;
        targetBadge.badgeDesc = props.badges.cdDesc;
      }
      return targetBadge;
    });

    const handleClick = () => {
      props.clickFunc(badgeMeta.value);
    };

    return {
      isMobile, badgeMeta, handleClick
    };
  },
};
</script>
