<template>
  <article class="content-section" :class="{'section-spacing': !isMobile}">
    <header class="section-header">
      <h4 class="title">자격증 마일리지 상세 현황</h4>
    </header>
    <div v-if="isMobile" class="text-item-container">
      <div class="text-item" v-for="(item, index) in pagedItems" :key="index">
        <h5 class="item-title">{{ item.lcsnNm }}</h5>
        <div class="item-row">
          <span class="text-title">승인일자</span>
          <span class="text-description">{{ timestampToDateFormat(item.recYmd, 'yyyy.MM.dd') }}</span>
        </div>
        <div class="item-row">
          <span class="text-title">등급</span>
          <span class="text-description">{{ item.grd1Cd ? item.grd1Cd : '-' }}</span>
        </div>
        <div class="item-row">
          <span class="text-title">마일리지</span>
          <span class="text-description">{{ item.exptMlg ? item.exptMlg : '-' }}</span>
        </div>
        <div class="item-row">
          <span class="text-title">취득마일리지</span>
          <strong class="text-description" :class="{'text-primary': item.aplcnMlg}">{{ item.aplcnMlg ? item.aplcnMlg : '-' }}</strong>
        </div>
        <div class="item-row">
          <span class="text-title">비고</span>
          <span class="text-description">-</span>
        </div>
      </div>
    </div>
    <div v-else class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
          <col style="width:auto">
          <col style="width:128px">
          <col style="width:128px">
          <col style="width:132px">
          <col style="width:132px">
          <col style="width:360px">
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">자격증명</span></th>
          <th><span class="th-title">승인일자</span></th>
          <th><span class="th-title">등급</span></th>
          <th><span class="th-title">마일리지</span></th>
          <th><span class="th-title">취득 마일리지</span></th>
          <th><span class="th-title">비고</span></th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="items.length === 0">
          <td colspan="6"><div class="text-truncate text-center"><strong class="td-text">{{ currentYear }}년에 등재된 자격증이 없습니다.</strong></div></td>
        </tr>
        <template v-else>
          <tr v-for="(item, index) in pagedItems" :key="index">
            <td><div class="text-truncate"><strong class="td-text">{{ item.lcsnNm }}</strong></div></td>
            <td><span class="td-text">{{ timestampToDateFormat(item.recYmd,'yyyy.MM.dd') }}</span></td>
            <td><span class="td-text">{{ item.grd1Cd ? item.grd1Cd : '-' }}</span></td>
            <td><span class="td-text">{{ item.exptMlg ? item.exptMlg : '-' }}</span></td>
            <td><strong class="td-text" :class="{'text-primary': item.aplcnMlg}">{{ item.aplcnMlg ? item.aplcnMlg : '-' }}</strong></td>
            <td><span class="td-text">-</span></td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div style="margin-top: 64px">
      <CommonPaginationFront :paging="{
        pageNo: paging.pageNo,
        pageSize: paging.pageSize,
        totalCount: totalCount
        }" :page-func="pagingFunc"/>
    </div>

    <div v-if="isMobile" class="section-bottom mt-5">
      <div class="bartexts">
        <span class="text text-brown">{{ currentYear }}년 연수 마일리지 부여기간</span>
        <span class="text text-brown">
           {{ currentYear }}.01.01 ~ {{ currentYear }}.12.31
        </span>
      </div>
    </div>

  </article>
</template>

<script>
import {paginate, timestampToDateFormat} from '@/assets/js/util';
import {computed, reactive} from 'vue';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: "MyPageMileageLicense",
  components: {CommonPaginationFront},
  props: {
    items: Array,
    currentYear: Number
  },
  setup(props) {
    const isMobile = navigationUtils.any();
    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
    });

    const totalCount = computed(() => props.items.length);
    const pagedItems = computed(() => paginate(props.items, paging.pageNo, paging.pageSize));

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
    }

    return {
      paging,
      totalCount,
      pagedItems,
      pagingFunc,
      timestampToDateFormat,
      isMobile,
    }
  }

}
</script>
