<template>
  <div class="popup-container" :class="{'is-active': modelValue}">
    <div class="popup" id="popup-mypage-z10">
      <div class="popup-inner">
        <form action="">
          <div>
            <header class="popup-header" style="margin-bottom: 10px;">
              <h3 class="title">연수이력 인쇄</h3>
            </header>
          </div>
          <div class="popup-content">
            <div class="popup-actions">
              <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="printHist"
                      :disabled="filteredLearns.length === 0">
                <i class="icon-print-doc"></i>
                <span class="text">이력인쇄</span>
              </button>
              <!--              <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="printCert"-->
              <!--                      :disabled="filteredLicenses.length === 0">-->
              <!--                <i class="icon-print-certificate"></i>-->
              <!--                <span class="text">수료증출력</span>-->
              <!--              </button>-->
            </div>
            <article class="popup-section">
              <header class="section-header">
                <h4 class="title">연수이력 ({{ filteredLearns.length }}건)</h4>
              </header>
            </article>
            <article class="popup-section"
                     style="height: 600px; overflow-x: hidden; overflow-y: scroll; margin-top: 10px;">
              <div class="kb-table kb-table-bordered kb-table-row">
                <table>
                  <colgroup>
                    <col style="width:auto">
                    <col style="width:140px">
                    <col style="width:90px">
                    <col style="width:70px">
                    <col style="width:70px">
                  </colgroup>
                  <thead>
                  <tr>
                    <th><span class="th-title">과정명</span></th>
                    <th><span class="th-title">연수기간</span></th>
                    <th><span class="th-title">점수</span></th>
                    <th><span class="th-title">결과</span></th>
                    <th class="cell-center"><span class="th-title">
                      <div class="">
                        <input
                            id="lrnsCheckAll"
                            type="checkbox"
                            class="kb-form-check-input"
                            @click="handleCheckAll"
                        />
                      </div>
                    </span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in filteredLearns" :key="`learn-${index}`">
                    <td>
                      <strong class="td-text">{{ item.crseNm }}</strong>
                      <p style="margin-top: 5px;font: var(--kb-body14-fontB);color: var(--kb-silver);">
                        {{ item.mediaCateNm }} | {{ item.orgnzNm }}
                      </p>
                    </td>
                    <td><span class="td-text">
                      {{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} -
                      {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span></td>
                    <td><span class="td-text">{{ getLearningScore(item) }}</span></td>
                    <td>
                      <div class="item-status">
                        <span class="td-text">
                          {{ getLearnStatusText(getLearningStatus(item)) }}
                        </span>
                      </div>
                    </td>
                    <td class="cell-center">
                      <div class="">
                        <input
                            :key="`lrns-chk-${index}`"
                            :value="item"
                            v-model="lrnsCheckList"
                            type="checkbox"
                            class="kb-form-check-input"/>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </article>

            <article class="popup-section" style="margin-top: 40px">
              <header class="section-header">
                <h4 class="title">자격증이력 ({{ filteredLicenses.length }}건)</h4>
              </header>
            </article>
            <article class="popup-section"
                     style="height: 350px; overflow-x: hidden; overflow-y: scroll; margin-top: 10px;">
              <div class="kb-table kb-table-bordered kb-table-row">
                <table>
                  <colgroup>
                    <col style="width:auto">
                    <col style="width:260px">
                    <col style="width:100px">
                    <col style="width:130px">
                    <col style="width:100px">
                    <col style="width:110px">
                    <col style="width:100px">
                  </colgroup>
                  <thead>
                  <tr>
                    <th><span class="th-title">자격증명</span></th>
                    <th><span class="th-title">주관처</span></th>
                    <th><span class="th-title">코드</span></th>
                    <th><span class="th-title">자격적용일</span></th>
                    <th><span class="th-title">등급</span></th>
                    <th><span class="th-title">지원금액</span></th>
                    <th class="cell-center"><span class="th-title">
                      <div class="">
                        <input
                            id="lcnsCheckAll"
                            type="checkbox"
                            class="kb-form-check-input"
                            @click="handleCheckAll"
                        />
                      </div>
                    </span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in filteredLicenses" :key="`license-${index}`">
                    <td><strong class="td-text">{{ item.lcsnNm }}</strong></td>
                    <td><span class="td-text">{{ item.pblcnInstNm }}</span></td>
                    <td><span class="td-text">{{ item.lcsnCd }}</span></td>
                    <td><span class="td-text">{{ item.passYmd }}</span></td>
                    <td><span class="td-text">{{ item.grd1Cd ? item.grd1Cd : '-' }}</span></td>
                    <td><span class="td-text">{{ item.sprtAmt > 0 ? numberComma(item.sprtAmt) : '-' }}</span></td>
                    <td class="cell-center">
                      <div class="">
                        <input
                            :key="`lcns-chk-${index}`"
                            :value="item"
                            v-model="lcnsCheckList"
                            type="checkbox"
                            class="kb-form-check-input"
                        />
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="kb-table-bottom pt-4">
                <!--                <div class="texticon"><i class="icon-notice"></i><strong class="text text-muted">과정(자격증)을 선택 후 인쇄 또는 수료증-->
                <!--                  출력을 진행해주세요. 단, 수료증출력은 연수이력만 가능합니다. </strong></div>-->
                <div class="texticon"><i class="icon-notice"></i><strong class="text text-muted">과정(자격증)을 선택 후 인쇄를 진행해주세요.</strong></div>
              </div>
            </article>

          </div>
        </form>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
  <MyPagePrintModal
      v-if="myPagePrintModal"
      v-model="myPagePrintModal"
      :init-effected="effected"
      :bodies="bodies"
  />
</template>

<script>
import navigationUtils from '@/assets/js/navigationUtils';
import {useAlert} from '@/assets/js/modules/common/alert';
import {numberComma, timestampToDateFormat} from '@/assets/js/util';
import {computed, ref} from 'vue';
import MyPagePrintModal from '@/components/mypage/MyPagePrintModal';

export default {
  name: 'MyPageHistoryPrint',
  components: {MyPagePrintModal},
  props: {
    learns: Array,
    licenses: Array,
    modelValue: Boolean,
  },
  setup(props, {emit}) {
    const isMobile = navigationUtils.any();
    const {showMessage} = useAlert();
    const currentDate = new Date();
    const currentTs = currentDate.getTime();
    const bodies = ref([]);
    const filteredLearns = computed(
        () => props.learns.filter(x => (x.fnshYn === 'Y' || x.fnshYn === 'N') && x.endDt > 0 && currentTs > x.endDt)
            .filter(x => x.auditCfmtnCd === 'Y')
            .sort((a, b) => {
              if (a.bgngDt > b.bgngDt) {
                return -1;
              }
              return 0;
            }));

    const filteredLicenses = computed(() => props.licenses
        .filter(x => x.passYmd !== '')
        .sort((a,b) => {if(a.lcsnNm < b.lcsnNm){ return -1} return 0;})
        .sort((a,b) => {if(a.passYmd > b.passYmd){ return -1} return 0;})
    );
    const effected = ref(0);
    const myPagePrintModal = ref(false);
    const lrnsCheckList = ref([]);
    const lcnsCheckList = ref([]);

    const printHist = () => {
      // console.log(lrnsCheckList.value);
      effected.value++;
      bodies.value = [];
      bodies.value.push(...lrnsCheckList.value.map(x => ({...x, target: 'lrns'})));
      bodies.value.push(...lcnsCheckList.value.map(x => ({...x, target: 'lcns'})));
      if (bodies.value.length > 0) {
        myPagePrintModal.value = true;
      } else {
        showMessage('인쇄할 항목을 선택해주세요.');
      }
      // console.log(bodies.value);
    };

    const printCert = () => {
      showMessage('준비중입니다.');
    };

    const finishOptions = [
      {value: 1, display: '수료'},
      {value: 2, display: '미수료'},
      {value: 3, display: '결과처리중'},
      {value: 4, display: '학습진행중'},
      {value: 5, display: '-'},
    ];

    const getLearningStatus = (item) => {
      // 학습 시작전
      if (currentTs < item.bgngDt) {
        return 5;
      } else if (currentTs >= item.bgngDt && currentTs <= item.endDt) {
        return 4;
      } else if (currentTs > item.endDt) {
        if (item.fnshYn === 'Y') {
          return 1;
        } else if (item.fnshYn === 'N') {
          return 2;
        }
      }
      return 3;

    };

    const getLearnStatusText = (status) => {
      const target = finishOptions.find(x => x.value === status);
      if (target) {
        return target.display;
      }
      return '-';
    };

    const getLearningScore = (item) => {
      return item.getScore !== null && item.getScore !== undefined ? item.getScore : '-';
    };

    const handleCheckAll = (e) => {
      // console.log(e.target.id, e.target.checked);
      if (e.target.id === 'lrnsCheckAll' && e.target.checked) {
        lrnsCheckList.value = filteredLearns.value;
      } else if (e.target.id === 'lrnsCheckAll' && !e.target.checked) {
        lrnsCheckList.value = [];
      } else if (e.target.id === 'lcnsCheckAll' && e.target.checked) {
        lcnsCheckList.value = filteredLicenses.value;
      } else if (e.target.id === 'lcnsCheckAll' && !e.target.checked) {
        lcnsCheckList.value = [];
      }
    };

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    return {
      isMobile,
      closeModal,
      printHist,
      printCert,
      numberComma,
      timestampToDateFormat,
      getLearningScore,
      getLearningStatus,
      getLearnStatusText,
      filteredLearns,
      filteredLicenses,
      bodies,
      effected,
      myPagePrintModal,
      lrnsCheckList,
      lcnsCheckList,
      handleCheckAll,
    };
  },
};
</script>

<style scoped>

.lxp-layout .kb-table-bordered th, .lxp-layout .kb-table-bordered td {
  min-height: 49px;
  padding: 16px 0;
  padding-right: 0;
  text-align: left;
}

</style>