<template>
  <!-- 글로벌 어학평가 -->
  <article class="content-section section-spacing" id="global-exam">
    <header class="section-header" :class="{'d-flex': isMobile }">
      <h4 class="title">글로벌 어학평가회 <span class="text text-muted">舊 글로벌 어학박람회</span></h4>
    </header>
    <div class="gtelp-container">
      <div class="kb-table" :class="{'kb-table-bordered': isMobile, 'kb-table-bordered-v2': !isMobile }">
        <table>
          <colgroup v-if="isMobile">
            <col style="width:176px;">
            <col style="width:102px;">
            <col style="width:auto">
            <col style="width:auto">
            <col style="width:auto">
            <col style="width:auto">
            <col style="width:176px">
          </colgroup>
          <colgroup v-else>
            <col style="width:290px;">
            <col style="width:200px;">
            <col style="width:auto">
            <col style="width:auto">
            <col style="width:auto">
            <col style="width:auto">
            <col style="width:237px">
          </colgroup>
          <thead>
          <tr>
            <th rowspan="2" class="align-top"><span class="th-title">평가명</span></th>
            <th rowspan="2" class="align-top"><span class="th-title">응시일자</span></th>
            <th colspan="2"><span class="th-title">회화</span></th>
            <th colspan="2"><span class="th-title">작문</span></th>
            <th rowspan="2" class="align-top"><span class="th-title">주관처</span></th>
          </tr>
          <tr v-if="isMobile">
            <th class="padding-s"><span class="th-title">등급</span></th>
            <th><span class="th-title">성적</span></th>
            <th><span class="th-title">등급</span></th>
            <th class="border-e"><span class="th-title">성적</span></th>
          </tr>
          <tr v-else>
            <th><span class="th-title">등급</span></th>
            <th><span class="th-title">성적</span></th>
            <th><span class="th-title">등급</span></th>
            <th><span class="th-title">성적</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="items.length === 0">
            <td colspan="7"><div class="text-truncate text-center"><strong class="td-text">데이터가 존재하지 않습니다.</strong></div></td>
          </tr>
          <template v-else>
            <tr v-for="(item, idx) in items" :key="idx">
              <td><strong class="td-text"><a href="javascript:" class="text-under-line" @click="showDetail(item.frlngGradeSn)">{{item.crseNm}}</a></strong></td>
              <td><strong class="td-text">{{item.examDd ? ymdStrToFormat(item.examDd, '.') : '-'}}</strong></td>
              <td><strong class="td-text">{{item.convGrd  != null ? item.convGrd+'등급' : '-'}}</strong></td>
              <td><strong class="td-text">{{item.convScore > 0 ? item.convScore+'점' : '-'}}</strong></td>
              <td><strong class="td-text">{{item.compoGrd  != null ? item.compoGrd+'등급' : '-'}}</strong></td>
              <td><strong class="td-text">{{item.compoScore > 0 ? item.compoScore+'점' : '-'}}</strong></td>
              <td><strong class="td-text">{{item.prvdrNm ? item.prvdrNm : '-'}}</strong></td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </article>

<!--  <article v-if="isMobile"  class="content-section">
    <header class="section-header d-flex">
      <h4 class="title">은행실무종합과정  <span class="text text-muted">舊 직원실무평가</span></h4>
    </header>
    &lt;!&ndash; text-item-container &ndash;&gt;
    <div class="text-item-container">
      &lt;!&ndash; text-item &ndash;&gt;
      <template v-if="items.length > 0">
        <div v-for="(item, idx) in items" :key="idx" class="text-item">
          <div class="item-row">
            <span class="text-title">평가년도</span>
            <span class="text-description">{{ item.ended }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">성적</span>
            <span class="text-description">{{ item.paccept }}점</span>
          </div>
        </div>
      </template>
      <div v-else class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">데이터가 존재하지 않습니다.</p>
        </div>
      </div>
    </div>
  </article>

  <article v-else class="content-section section-spacing">
    <header class="section-header">
      <h4 class="title">은행실무종합과정  <span class="text text-muted">舊 직원실무평가</span></h4>
    </header>
    <div class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">평가년도</span></th>
          <th><span class="th-title">성적</span></th>
        </tr>
        </thead>
        <tbody>
          <tr v-if="items.length === 0">
            <td colspan="2"><div class="text-truncate text-center"><strong class="td-text">데이터가 존재하지 않습니다.</strong></div></td>
          </tr>
          <template v-else>
            <tr v-for="(item, idx) in items" :key="idx">
              <td><strong class="td-text">{{item.ended}}</strong></td>
              <td><strong class="td-text">{{item.paccept}}점</strong></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </article>-->
  <MyGlobalModal v-if="!isMobile" v-model="detailModal" :item="item" :lrner-id="session.lrnerId" />
</template>

<script>

import navigationUtils from "@/assets/js/navigationUtils";
import {ymdStrToFormat} from "@/assets/js/util";
import {computed, ref} from 'vue';
import {useStore} from "vuex";
import MyGlobalModal from "@/components/my/MyGlobalModal";
import {useRouter} from "vue-router";


export default {
  name: "MyPageGlobal",
  components: {MyGlobalModal},
  props: {
    items: Array
  },
  setup(props){
    const store = useStore();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const isMobile = navigationUtils.any();
    const item = ref([]);
    const detailModal = ref(false);
    const showDetail = (pFrlngGradeSn) => {
      item.value = props.items.filter(x => x.frlngGradeSn === pFrlngGradeSn)[0];
      if(isMobile){
        router.push({name: 'MobileMyPageGlobal', params:{ frlngGradeSn: pFrlngGradeSn }});
      }else{
        detailModal.value = true;
      }
    }

    return{
      showDetail,
      detailModal,
      isMobile,
      ymdStrToFormat,
      session,
      item,
    }
  }
}
</script>

<style scoped>
#global-exam .text-under-line  {text-decoration: underline;}
</style>