<template>
  <article class="content-section" :class="{'section-divider' : !isMobile}">
    <header class="section-header">
      <h4 class="title">학습 바로가기</h4>
    </header>
    <div v-if="isMobile" class="symbol-item-wrap">
      <!-- symbol-item -->
      <div class="symbol-item">
        <router-link :to="{name: 'TrainRegular'}" class="symbol-link"/>
        <div class="item-icon icon-yellow"><i class="icon-symbol-rule"></i></div>
        <div class="item-body">
          <div class="content-title">직무수행교육 학습</div>
          <i class="icon-arrow"></i>
        </div>
      </div>
      <!-- symbol-item -->
      <div class="symbol-item">
        <router-link :to="{name : 'TrainProceeding', query: {classCateSn: 42}}" class="symbol-link"/>
        <div class="item-icon icon-blue"><i class="icon-symbol-edu"></i></div>
        <div class="item-body">
          <div class="content-title">통합법정교육 학습</div>
          <i class="icon-arrow"></i>
        </div>
      </div>
      <!-- symbol-item -->
      <div class="symbol-item">
        <a href="javascript:" class="symbol-link" @click="openPopUp('https://lxp.kbstar.com/wise/trust')"></a>
        <div class="item-icon icon-red"><i class="icon-symbol-clap"></i></div>
        <div class="item-body">
          <div class="content-title">칭찬 시스템</div>
          <i class="icon-arrow"></i>
        </div>
      </div>
    </div>
    <div v-else class="barlink-wrap">
      <ul class="barlink-list">
        <li class="barlink-item">
          <router-link :to="{name: 'TrainRegular'}" class="barlink barlink-yellow">
            <i class="icon-symbol-rule"></i>
            <span class="title">직무수행교육 학습</span>
            <span class="arrow"><i class="icon-arrow"></i></span>
          </router-link>
        </li>
        <li class="barlink-item">
          <router-link :to="{name : 'TrainProceeding', query: {classCateSn: 42}}" class="barlink barlink-blue">
            <i class="icon-symbol-edu"></i>
            <span class="title">통합법정교육 학습</span>
            <span class="arrow"><i class="icon-arrow"></i></span>
          </router-link>
        </li>
        <li class="barlink-item">
          <a href="javascript:" class="barlink barlink-red" @click="openPopUp('https://lxp.kbstar.com/wise/trust')">
            <i class="icon-symbol-clap"></i>
            <span class="title">칭찬 시스템</span>
            <span class="arrow"><i class="icon-arrow"></i></span>
          </a>
        </li>
      </ul>
    </div>
  </article>
</template>

<script>

import navigationUtils from "@/assets/js/navigationUtils";
import {customPageMove} from "@/assets/js/modules/common/common";
import {openPopUp} from "@/assets/js/modules/ssl/ssl-common";

export default {
  name: "MyPageLink",
  setup() {

    return {
      isMobile: navigationUtils.any(),
      customPageMove,
      openPopUp
    }
  }
}
</script>
