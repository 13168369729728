<template>
  <article class="content-section">
    <header class="section-header">
      <h4 class="title">나의 연수 현황</h4>
    </header>
    <!-- training-status-container -->
    <div class="training-status-container">
      <div class="symbol-card-wrap" :class="{'symbol-item-wrap' :isMobile}">
        <!-- symbol-card -->
        <div class="symbol-card" :class="{'symbol-item' : isMobile}">
          <router-link :to="{name: 'TrainProceeding'}" :class="isMobile ? 'symbol-link' : 'card-link'"></router-link>
          <div class="card-icon" :class="{'item-icon' : isMobile}"><i class="icon-progress"></i></div>
          <div class="card-content item-body">
            <div class="content-title">{{ isMobile ? '진행중 연수' : `${proceeding}개`}}</div>
            <div class="content-subhead">{{ isMobile? `${proceeding}개` : '진행중 연수' }}</div>
          </div>
        </div>
        <!-- symbol-card -->
        <div class="symbol-card" :class="{'symbol-item' : isMobile}">
          <router-link :to="{name: 'TrainScheduled'}" :class="isMobile ? 'symbol-link' : 'card-link'"></router-link>
          <div class="card-icon" :class="{'item-icon' : isMobile}"><i class="icon-intended"></i></div>
          <div class="card-content item-body">
            <div class="content-title">{{ isMobile ? '예정된 연수' : `${scheduled}개`}}</div>
            <div class="content-subhead">{{ isMobile? `${scheduled}개` : '예정된 연수' }}</div>
          </div>
        </div>
        <!-- symbol-card -->
        <div class="symbol-card" :class="{'symbol-item' : isMobile}">
          <router-link :to="{name: 'MyPageMain', query: {'view': 'history'}}" :class="isMobile ? 'symbol-link' : 'card-link'"></router-link>
          <div class="card-icon" :class="{'item-icon' : isMobile}"><i class="icon-completed"></i></div>
          <div class="card-content item-body">
            <div class="content-title">{{ isMobile ? '완료된 연수' : `${completed}개`}}</div>
            <div class="content-subhead">{{ isMobile? `${completed}개` : '완료된 연수' }}</div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>

import {computed} from 'vue';
import navigationUtils from "@/assets/js/navigationUtils";
import {getTodayDate, timestampToDateFormat} from "@/assets/js/util";

export default {
  name: "MyPageLearnStatus",
  props: {
    items: Array
  },
  emits: ['changeView'],
  setup(props, {emit}) {
    const currentTs = new Date().getTime();
    const proceeding = computed(() => props.items
        .filter(x => x.endDt >= currentTs && x.bgngDt <= currentTs && (x.logCrseFg === 'Y'||x.splCrseTyCdDcd ==='2066024')) // 의무교육포함
        .filter(x => {
          if(!['2066006','2066008','2066009','2066017','2066022','2066031'].includes(x.splCrseTyCdDcd) && x.crseMstSn !== 15224) return true;
        })
        .length);
    const scheduled = computed(() => props.items
        .filter(x => x.bgngDt > currentTs && (x.logCrseFg === 'Y'||x.splCrseTyCdDcd ==='2066024'))
        .filter(x => {
          if(!['2066006','2066008','2066009','2066017','2066022','2066031'].includes(x.splCrseTyCdDcd) && x.crseMstSn !== 15224) return true;
        })
        .length);
    const completed = computed(() => props.items
        .filter(x => x.endDt < currentTs && timestampToDateFormat(x.bgngDt, 'yyyy') == getTodayDate('yyyy') && (x.logCrseFg === 'Y'||x.splCrseTyCdDcd ==='2066024'))
        .filter(x => {
          if(!['2066006','2066008','2066009','2066017','2066022','2066031'].includes(x.splCrseTyCdDcd) && x.crseMstSn !== 15224) return true;
        })
        .length);

    const goHistory = () => {
      emit('changeView', 'history');
    }

    return {
      proceeding,
      scheduled,
      completed,
      goHistory,
      isMobile: navigationUtils.any()
    }
  }
}
</script>
