<template>
  <div class="" :class="{'content-section-group section-divider' : !isMobile}">
    <!-- content-section:직무인증제 -->
    <article class="content-section" style="margin-right: 8px">
      <header class="section-header">
        <h4 class="title">직무인증제</h4>
      </header>
      <div class="kb-table kb-table-round">
        <table>
          <tbody>
            <tr>
              <th>
                <div class="badge-wrap">
                  <div class="badge badge" :class="individualCdNms.length > 0 ? 'badge-primary' : 'badge-silver'">
                    <span class="badge-text">{{ individualCdNms.length > 0 ? '충족' : '미충족' }}</span>
                  </div>
                  <strong class="th-title">개인금융</strong>
                </div>
              </th>
              <td><span class="td-text">{{ individualCdNms.length > 0 ? individualCdNms : '-' }}</span></td>
            </tr>
            <tr>
              <th>
                <div class="badge-wrap">
                  <div class="badge badge" :class="enterpriseCdNms.length > 0 ? 'badge-primary' : 'badge-silver'">
                    <span class="badge-text">{{ enterpriseCdNms.length > 0 ? '충족' : '미충족' }}</span>
                  </div>
                  <strong class="th-title">기업금융</strong>
                </div>
              </th>
              <td><span class="td-text">{{ enterpriseCdNms.length > 0 ? enterpriseCdNms : '-' }}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="kb-table-buttons">
        <router-link :to="{name: 'MyPageMain', query: {'view': 'certification'}}" class="kb-btn kb-btn-outline"><strong class="text">전체보기</strong></router-link>
      </div>
      <div v-if="certficationFiledItem.j1Flag === 'Y' && certficationFiledItem.j2Flag === 'Y'" class="kb-table-bottom">
        <div class="texticon">
          <i class="icon-check"></i>
          <strong class="text">부점장 승진 자격요건을 <em class="text-vivid-blue">충족</em>하였습니다.</strong>
        </div>
      </div>
    </article>
    <!-- content-section:나의 직급별 필수과정 -->
    <MyPageDashboardJbgdEssential v-if="isKbBank"/>
  </div>

</template>

<script>

import {useStore} from 'vuex';
import {computed, ref} from 'vue';
import {certificationConfigs} from '@/assets/js/modules/mypage/mypage-common';
import navigationUtils from "@/assets/js/navigationUtils";
import {
    enterpriseAllCds,
    enterpriseCds,
    getJmJobCertList,
    getVicePromotionRequirementList,
    individualAllCds,
    individualCds
} from "@/assets/js/modules/learn/learn-common";
import MyPageDashboardJbgdEssential from "@/components/mypage/dashboard/MyPageDashboardJbgdEssential";


export default {
  name: "MyPageDashboardCertification",
  components: {MyPageDashboardJbgdEssential},
  emits: ['changeView'],
  setup(props, {emit}) {
    const store = useStore();
    // const router = useRouter()
    const session = computed(() => store.state.auth.session);
    const specialIndividualYn = ref('N');
    const specialEnterpriseYn = ref('N');
    const isKbBank = computed(() => session.value.susdyCdDcd && (session.value.susdyCdDcd === '2109001'));

    const changeView = () => {
      emit('changeView', 'certification');
    }

    const certficationFiledItem = ref({});
    getJmJobCertList(certficationFiledItem);
    const vicePromotionRequirementItems = ref([]);
    getVicePromotionRequirementList(vicePromotionRequirementItems);

    const individualCdNms = computed(() => {
        const filtered = vicePromotionRequirementItems.value.filter(x => individualCds.includes(x.lcsnCd)).map(y => y.lcsnCd)
        if(filtered.length > 0){
            let individualCdArr = [];
            filtered.forEach(x => {
                if('J211' === x) specialIndividualYn.value = 'Y'
                individualAllCds.forEach(y => {
                    if(x === y.cd){
                        if(!individualCdArr.includes(y.type)){
                            individualCdArr.push(y.type)
                        }
                    }
                })
            })
            return individualCdArr.join();
        }
        return '';
    });

    const enterpriseCdNms = computed(() => {
        const filtered = vicePromotionRequirementItems.value.filter(x => enterpriseCds.includes(x.lcsnCd)).map(y => y.lcsnCd)
        if(filtered.length > 0){
            let enterpriseCdArr = [];
            filtered.forEach(x => {
                if('J221' === x || 'J220' === x) specialEnterpriseYn.value = 'Y'
                enterpriseAllCds.forEach(y => {
                    if(x === y.cd){
                        if(!enterpriseCdArr.includes(y.type)){
                            enterpriseCdArr.push(y.type)
                        }
                    }
                })
            })
            return enterpriseCdArr.join();
        }
        return '';
    });

    return {
      session,
      certificationConfigs,
      changeView,
      certficationFiledItem,
      enterpriseCdNms,individualCdNms,
      specialIndividualYn,specialEnterpriseYn,
      isMobile: navigationUtils.any(),
      isKbBank
    }
  }
}
</script>
