<template>
    <article class="content-section section-spacing">
      <header class="section-header">
        <h4 class="title">외국어 ・ IT평가</h4>
      </header>
      <div v-if="isMobile" class="text-item-container">
        <div v-for="(item, idx) in items" class="text-item" :key="idx">
          <p class="item-subtitle">{{ item.evlDivNm }}</p>
          <h5 class="item-title">{{ item.lrnCostFldNm }}</h5>
          <div class="item-row">
            <span class="text-title">응시일자</span>
            <span class="text-description">{{ item.evlExamDd }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">취득성적</span>
            <span class="text-description">{{ item.evlGrade ? item.evlGrade : '-' }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">주관처</span>
            <span class="text-description">{{ item.prvdrNm ? item.prvdrNm : '-' }}</span>
          </div>
        </div>
        <div v-if="items.length === 0" class="search-container">
          <div class="result-empty">
            <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">등재된 자격증이 존재하지 않습니다.</p>
          </div>
        </div>
      </div>
      <div v-else class="kb-table kb-table-bordered-v2">
        <table>
          <colgroup>
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">구분</span></th>
            <th><span class="th-title">평가명</span></th>
            <th><span class="th-title">응시일자</span></th>
            <th><span class="th-title">취득성적</span></th>
            <th><span class="th-title">주관처</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, idx) in items" :key="idx">
            <td><strong class="td-text">{{ item.evlDivNm }}</strong></td>
            <td><strong class="td-text">{{ item.lrnCostFldNm }}</strong></td>
            <td><strong class="td-text">{{ item.evlExamDd }}</strong></td>
            <td><strong class="td-text">{{ item.evlGrade ? item.evlGrade : '-' }}</strong></td>
            <td><strong class="td-text">{{ item.prvdrNm ? item.prvdrNm : '-' }}</strong></td>
          </tr>
          <tr v-if="items.length === 0">
            <td colspan="5"><div class="text-truncate text-center"><strong class="td-text">데이터가 존재하지 않습니다.</strong></div></td>
          </tr>
          </tbody>
        </table>
      </div>
    </article>
</template>

<script>
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: "MyPageEval",
  props: {
    items: Array
  },
  setup() {
    return {
      isMobile: navigationUtils.any()
    }
  }
}
</script>