<template>
  <article class="content-section">
    <header class="section-header">
      <h4 class="title">마일리지 취득현황</h4>
      <p class="sub-title" v-if="!isMobile">
        {{ currentYear }}년 연수 마일리지 부여기간 : {{ currentYear }}.01.01 ~ {{ currentYear }}.12.31</p>
    </header>
    <template v-if="isMobile">
      <div class="symbol-item-wrap">
        <div class="symbol-item">
          <div class="item-icon"><i class="icon-mileage-training"></i></div>
          <div class="item-body">
            <div class="content-title">연수 마일리지</div>
            <div class="content-num">{{ learnMileage }}점</div>
          </div>
        </div>
        <!-- symbol-item -->
        <div class="symbol-item">
          <div class="item-icon"><i class="icon-mileage-medal"></i></div>
          <div class="item-body">
            <div class="content-title">자격증 마일리지</div>
            <div class="content-num">{{ licenseMileage }}점</div>
          </div>
        </div>
        <div class="symbol-item">
<!--          <a class="symbol-link"></a>-->
          <div class="item-icon"><i class="icon-mileage-cloud"></i></div>
          <div class="item-body">
            <div class="content-title">HRD 클라우드 마일리지
                <span @click="goHrdCloudMlgDtl" class="kb-btn-pill">상세보기</span>
            </div>
            <div class="content-num">{{ hrdCloudMlg }}점</div>
          </div>
        </div>
        <!-- symbol-item -->
        <div class="symbol-item">
          <div class="item-icon"><i class="icon-mileage-check"></i></div>
          <div class="item-body">
            <div class="content-title">마일리지 총 합계</div>
            <div class="content-num">{{ totalScore }}점</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mileage-status-container">
        <div class="symbol-card-wrap" data-column="4">
          <!-- symbol-card -->
          <div class="symbol-card">
            <div class="card-icon"><i class="icon-mileage-training"></i></div>
            <div class="card-content">
              <div class="content-title">{{ learnMileage }}점</div>
              <div class="content-subhead">연수 마일리지</div>
            </div>
          </div>
          <!-- symbol-card -->
          <div class="symbol-card">
            <div class="card-icon"><i class="icon-mileage-medal"></i></div>
            <div class="card-content">
              <div class="content-title">{{ licenseMileage }}점</div>
              <div class="content-subhead">자격증 마일리지</div>
            </div>
          </div>
          <div class="symbol-card">
            <div class="card-icon"><i class="icon-mileage-cloud"></i></div>
            <div class="card-content">
              <div class="content-title">{{ hrdCloudMlg }}점
                <a href="javascript:" class="kb-btn-pill" @click="goHrdCloudMlgDtl">상세보기</a>
              </div>
              <div class="content-subhead">HRD 클라우드 마일리지</div>
            </div>
          </div>
          <!-- symbol-card -->
          <div class="symbol-card">
            <div class="card-icon"><i class="icon-mileage-check"></i></div>
            <div class="card-content">
              <div class="content-title">{{ totalScore }}점</div>
              <div class="content-subhead">마일리지 총 합계</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- //mileage-status-container -->
  </article>
</template>

<script>
import {computed} from 'vue';
import navigationUtils from '@/assets/js/navigationUtils';
import {useRouter} from "vue-router";

export default {
  name: 'MyPageMileageStatus',
  components: {},
  props: {
    learns: Array,
    licenses: Array,
    hrdCloudMlg: Number,
    currentYear: Number,
  },
  setup(props) {
    const router = useRouter();
    const learnMileage = computed(() => {
      let score = props.learns.map(x => (x.aplcnMlg || 0)).reduce((a, b) => (a + b), 0);
      return Math.trunc(score*100)/100;
    });
    const licenseMileage = computed(() => props.licenses.map(x => (x.aplcnMlg || 0)).reduce((a, b) => (a + b), 0));
    const totalScore = computed(() => {
        let score = learnMileage.value + licenseMileage.value + props.hrdCloudMlg;
        if(Math.ceil(score) > score) score = score.toFixed(2);
        return score || 0;
    });

    learnMileage.value = Math.trunc(learnMileage.value*100)
    const isMobile = navigationUtils.any();

    const goHrdCloudMlgDtl = () => {
        router.push({name: 'HistoryHrdCloud'});
    }

    return {
      learnMileage,
      licenseMileage,
      totalScore,
      isMobile,
      goHrdCloudMlgDtl
    };

  },
};
</script>