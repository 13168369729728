<template>
  <!-- content-section:직무인증제 -->
  <article class="content-section">
    <header class="section-header">
      <h4 class="title">직무인증제</h4>
    </header>
    <div class="badge-content-container">
      <div class="badge-content-item">
        <div class="item-row row-content">
          <div class="item-badge badge" :class="individualCdNms.length > 0 ? 'badge-primary' : 'badge-silver'">
              <span class="badge-text">{{ individualCdNms.length > 0 ? '충족' : '미충족' }}</span>
          </div>
          <h5 class="item-title">개인금융</h5>
          <span class="item-text">{{ individualCdNms.length > 0 ? individualCdNms : '-' }}</span>
        </div>
      </div>
      <div class="badge-content-item">
        <div class="item-row row-content">
          <div class="item-badge badge" :class="enterpriseCdNms.length > 0 ? 'badge-primary' : 'badge-silver'">
              <span class="badge-text">{{ enterpriseCdNms.length > 0 ? '충족' : '미충족' }}</span>
          </div>
          <h5 class="item-title">기업금융</h5>
          <span class="item-text">{{ enterpriseCdNms.length > 0 ? enterpriseCdNms : '-' }}</span>
        </div>
      </div>
    </div>
    <div class="section-buttons">
      <button class="kb-btn-content-item" @click="goCertification">전체보기</button>
    </div>

    <div v-if="certficationFiledItem.j1Flag === 'Y' && certficationFiledItem.j2Flag === 'Y'" class="section-bottom">
      <div class="texticon">
        <i class="icon-check"></i>
        <strong class="text">부점장 승진 자격요건을 <em class="text-blue">충족</em>하였습니다.</strong>
      </div>
    </div>
  </article>
  <!-- content-section:나의 직급별 필수과정 -->
  <MobileMyPageDashboardJbgdEssential v-if="isKbBank"/>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {certificationConfigs} from "@/assets/js/modules/mypage/mypage-common";
import navigationUtils from "@/assets/js/navigationUtils";
import {
    enterpriseAllCds,
    enterpriseCds,
    getJmJobCertList,
    getVicePromotionRequirementList,
    individualAllCds,
    individualCds
} from "@/assets/js/modules/learn/learn-common";
import MobileMyPageDashboardJbgdEssential
  from "@/components/mypage/dashboard/mobile/MobileMyPageDashboardJbgdEssential";
import {useRouter} from "vue-router";


export default {
  name: "MobileMyPageDashboardCertification",
  components:{
    MobileMyPageDashboardJbgdEssential
  },
  emits: ['changeView'],
  setup(props, {emit}) {
    const store = useStore();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const specialIndividualYn = ref('N');
    const specialEnterpriseYn = ref('N');
    const isKbBank = computed(() => session.value.susdyCdDcd && (session.value.susdyCdDcd === '2109001'));

    const changeView = () => {
      emit('changeView', 'certification');
    }

    const goCertification = () => {
      router.push({name: 'MyPageMain', query: {'view': 'certification'}});
    }

    const certficationFiledItem = ref({});
    getJmJobCertList(certficationFiledItem);

    const vicePromotionRequirementItems = ref([]);
    getVicePromotionRequirementList(vicePromotionRequirementItems);

    const individualCdNms = computed(() => {
        const filtered = vicePromotionRequirementItems.value.filter(x => individualCds.includes(x.lcsnCd)).map(y => y.lcsnCd)
        if(filtered.length > 0){
            let individualCdArr = [];
            filtered.forEach(x => {
                if('J211' === x) specialIndividualYn.value = 'Y'
                individualAllCds.forEach(y => {
                    if(x === y.cd){
                        if(!individualCdArr.includes(y.type)){
                            individualCdArr.push(y.type)
                        }
                    }
                })
            })
            return individualCdArr.join();
        }
        return '';
    });

    const enterpriseCdNms = computed(() => {
        const filtered = vicePromotionRequirementItems.value.filter(x => enterpriseCds.includes(x.lcsnCd)).map(y => y.lcsnCd)
        if(filtered.length > 0){
            let enterpriseCdArr = [];
            filtered.forEach(x => {
                if('J221' === x || 'J220' === x) specialEnterpriseYn.value = 'Y'
                enterpriseAllCds.forEach(y => {
                    if(x === y.cd){
                        if(!enterpriseCdArr.includes(y.type)){
                            enterpriseCdArr.push(y.type)
                        }
                    }
                })
            })
            return enterpriseCdArr.join();
        }
        return '';
    });

    return {
      session,
      certficationFiledItem,
      certificationConfigs,
      changeView,
      goCertification,
      enterpriseCdNms,individualCdNms,
      specialIndividualYn,specialEnterpriseYn,
      isMobile: navigationUtils.any(),
      isKbBank
    }
  }
}
</script>