<template>
  <MyPageLearnStatus :items="learns" @changeView="changeView"/>
  <MyPageProductLicense :items="licenses"/>

  <!-- 데이터베이스가 생성시 작업 예정 -->
  <template v-if="isMobile">
    <MobileMyPageDashboardCertification
        @changeView="changeView"
    />
  </template>
  <template v-else>
    <MyPageDashboardCertification
        @changeView="changeView"
    />
  </template>
  <!-- HRD 의 경우 추후 논의를 통해서 정책 확정 -->
  <!--  <MyPageHRD/> 2022.12.05 데이터 불일치 하여 비노출 처리(임시)-->
  <MyPageBadge :items="badges"/>
  <MyPageLink/>
</template>

<script>


import MyPageBadge from '@/components/mypage/dashboard/MyPageBadge';
import MyPageLink from '@/components/mypage/dashboard/MyPageLink';
import MyPageLearnStatus from '@/components/mypage/dashboard/MyPageLearnStatus';
import MyPageProductLicense from '@/components/mypage/dashboard/MyPageProductLicense';
import MyPageDashboardCertification from '@/components/mypage/dashboard/MyPageDashboardCertification';
import MobileMyPageDashboardCertification
  from "@/components/mypage/dashboard/mobile/MobileMyPageDashboardCertification";
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: "MyPageDashboard",
  components: {
    MobileMyPageDashboardCertification,
    MyPageDashboardCertification,
    MyPageProductLicense,
    MyPageLearnStatus, MyPageLink, MyPageBadge,},
  props: {
    learns: Array,
    licenses: Array,
    badges: Array,
  },
  emits: ['changeView'],
  setup(props, {emit}) {
    const changeView = (view) => {
      emit('changeView', view);
    }

    return {
      changeView,
      isMobile: navigationUtils.any()
    }
  }
}
</script>
