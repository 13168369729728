<template>
  <article v-if="session.ficbjjyb === '1' && session.vice === true" class="content-section" style="margin-top: 0; margin-left: 8px">
    <header class="section-header">
      <h4 class="title">나의 직급별 필수과정</h4>
    </header>
    <div class="kb-table kb-table-round">
      <table>
        <colgroup>
          <col style="width:252px" />
          <col />
        </colgroup>
        <tbody>
        <tr>
          <th class="align-top">
            <strong class="th-title">은행실무종합과정</strong><br/>
            <div class="text-muted" style="font-size: 12px; font-weight:normal; margin-top: 5px;">
              <span class="text-muted">* 성적 상위 2회분 표기</span>
            </div>
          </th>
          <td>
            <span class="text-muted">대상이 아닙니다.</span>
          </td>
        </tr>

        <tr>
          <th class="align-top"><strong class="th-title">신임과정</strong></th>
          <td>
            <span class="text-muted">대상이 아닙니다.</span>
          </td>
        </tr>

        <tr>
          <th class="align-top"><strong class="th-title">직무필수</strong></th>
          <td>
            <span class="text-muted">대상이 아닙니다.</span>
          </td>
        </tr>
        <tr>
          <th class="align-top"><strong class="th-title">예비</strong></th>
          <td>
            <span class="text-muted">대상이 아닙니다.</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="kb-table-buttons">
      <router-link :to="{name: 'MyAbility'}" class="kb-btn kb-btn-outline"><strong class="text">전체보기</strong></router-link>
    </div>
  </article>
  <article v-else class="content-section" style="margin-top: 0; margin-left: 8px">
    <header class="section-header">
      <h4 class="title">나의 직급별 필수과정</h4>
    </header>
    <div class="kb-table kb-table-round">
      <table>
        <colgroup>
          <col style="width:252px" />
          <col />
        </colgroup>
        <tbody>
        <tr>
          <th class="align-top">
            <strong class="th-title">은행실무종합과정</strong><br/>
            <div class="text-muted" style="font-size: 12px; font-weight:normal; margin-top: 5px;">
              <span class="text-muted">* 성적 상위 2회분 표기</span>
            </div>
          </th>
          <td>
            <!--              <span class="text-muted">준비중입니다.</span>-->
            <div v-if="AbilityBankYn == 'Y'" class="td-cell">
              <div class="progress-checklist">
                <div v-for="(item, idx) in newItems" :key="idx" class="check-item" :class="{'is-active' : item.reflectTarget == '반영대상'}">
                  <div class="check"></div>
                  <div class="check-label">{{item.ended?.split('.')[0]}}</div>
                  <div class="check-label">({{item.paccept}}점)</div>
                </div>
              </div>
            </div>
            <span v-else class="text-muted">필수 연수가 아닙니다.</span>
          </td>
        </tr>

        <tr>
          <th class="align-top"><strong class="th-title">신임과정</strong></th>
          <td>
            <!--              <span class="text-muted">준비중입니다.</span>-->
            <div v-if="newYn == 'Y'" class="td-cell">
              <div class="progress-checklist">
                <!-- check-item -->
                <div class="check-item" :class="{'is-active' : lrnerCdp.jkgupBeginYear != null}">
                  <div class="check"></div>
                  <div class="check-label">{{ lrnerCdp.jkgupBeginYear }}</div>
                </div>
              </div>
            </div>
            <span v-else class="text-muted">필수 연수가 아닙니다.</span>
          </td>
        </tr>

        <tr>
          <th class="align-top"><strong class="th-title">직무필수</strong></th>
          <td>
            <div class="td-cell">
              <div class="progress-checklist">
                <div v-for="(item, idx) in ficjikmuItems" :key="idx" class="check-item" :class="{'is-active' : item.nodata != 'Y', 'is-sel-dist' : item.reqYn !='Y' }">
                  <div class="check"></div>
                  <div class="check-label">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th class="align-top"><strong class="th-title">예비</strong></th>
          <td>
            <div class="td-cell">
              <div class="progress-checklist">
                <div class="check-item" :class="{'is-active' : lrnerCdp.jkgupGradeYear !== undefined && lrnerCdp.jkgupGradeYear != null}">
                  <div class="check"></div>
                  <div class="check-label">승격예비</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="kb-table-buttons">
      <router-link :to="{name: 'MyAbility'}" class="kb-btn kb-btn-outline"><strong class="text">전체보기</strong></router-link>
    </div>
  </article>
</template>

<script>
import {useStore} from 'vuex';
import {computed, onMounted, ref} from "vue";
import {
  ACT_GET_MY_ABILITY_CREDIT_BANK_LIST,
  ACT_GET_MY_ABILITY_RESULT
} from "@/store/modules/my/my";
import {getItem,
  getItems,
  lengthCheck} from "@/assets/js/util";
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: "MyPageDashboardJbgdEssential",
  setup(){

    const store = useStore();

    const session = computed(() => store.state.auth.session);

    const banks = ref([]);
    const newItems = ref([]);
    const gradeItems = ref([]);
    const ficjikmuItems = ref([]);
    const newYn = ref('Y') // 은실종: N, 신임과정: Y
    // 신임L1 승격자는 신임과정이 필수이므로 은실종과 신임과정 분리
    const AbilityBankYn = ref('N');
    const lrnerCdp = ref({});

    onMounted(() => {
      getMyAbility();
    })

    const getMyAbility = async() => {
      await store.dispatch(`my/${ACT_GET_MY_ABILITY_RESULT}`, session.value.lrnerId).then(res => {
        if (lengthCheck(res)) {
          lrnerCdp.value = getItem(res);
        } else {
          lrnerCdp.value = {};
        }


        //lrnerCDP
        console.log("lrnercdp", lrnerCdp.value);

        if(lrnerCdp.value && lrnerCdp.value.ficjkgup && (
            lrnerCdp.value.ficjkgup.indexOf('S0') > -1
            || lrnerCdp.value.ficjkgup.indexOf('L0') > -1
            ||
            (//L1(S1)의 경우 전직급승격일 != 현직급승격일이 다르면 신임과정 대상
              (lrnerCdp.value.ficjkgup.indexOf('L1') > -1|| lrnerCdp.value.ficjkgup.indexOf('S1') > -1)
              && lrnerCdp.value.fidjjgsg == lrnerCdp.value.fidhjgsg
            )
        )){
          newYn.value = 'N'
        }else{
          if(lrnerCdp.value && lrnerCdp.value.ficjkgup && (lrnerCdp.value.ficjkgup.indexOf('L4') > -1 || lrnerCdp.value.ficjkgup.indexOf('S4') > -1)){
            newYn.value = ''
          }else{
            newYn.value = 'Y'
          }
        }

        if(lrnerCdp.value && lrnerCdp.value.ficjkgup && (
            lrnerCdp.value.ficjkgup.indexOf('S0') > -1
            || lrnerCdp.value.ficjkgup.indexOf('L0') > -1
            || lrnerCdp.value.ficjkgup.indexOf('L1') > -1
            || lrnerCdp.value.ficjkgup.indexOf('S1') > -1)
        ){
          AbilityBankYn.value = 'Y';
        }


        // cdp결과 조회후 다른목록들 조회
        if(AbilityBankYn.value === 'Y')  {
          getAbilityBankList(); // 은실종
        }


        for(let i=0; i<lrnerCdp.value.jikmuReqCountDefault; i++){

          let item = {title:"",nodata:"", reqYn:""}
          if(i>=2){
            item.title = `선택${i-1}`
            item.reqYn = "N";
          }else{
            item.title = `공통${i+1}`
            item.reqYn = "Y";
          }
          item.nodata = "Y";
          //공통/선택 2,2개 분리반영
          //공통 0,1
          if(i<2){
            if(i<lrnerCdp.value.pubSubj) {
              item.nodata = "N";
            }
          }
          if(i>=2){
            if((i-2)<lrnerCdp.value.selSubj) {
              item.nodata = "N";
            }
          }
          // if(i < lrnerCdp.value.jikmuReqCount){
          //   item.nodata = "N";
          //   // ficjikmuItems.value.push({nodata: 'N'})
          // }else{
          //   item.nodata = "Y";
          // }
          ficjikmuItems.value.push(item);
        }
      }).catch(e => {
        console.error(e);
      })
    }

    // KB은행실무종합과정
    const getAbilityBankList = async () => {
      await store.dispatch(`my/${ACT_GET_MY_ABILITY_CREDIT_BANK_LIST}`, {
        cdpResultId: lrnerCdp.value.cdpResultId
      }).then(res => {
        if(lengthCheck(res)){
          newItems.value = getItems(res);
        }else{
          newItems.value = [];
        }

        if(newItems.value.length == 1){
          newItems.value.push({'reflectTarget': '미반영대상'})
        }else if(newItems.value.length == 0){
          for(let i=0; i<2; i++){
            newItems.value.push({'reflectTarget': '미반영대상'})
          }
        }else{
          newItems.value = newItems.value.filter((x) => x.reflectTarget.indexOf('반영대상') > -1)
        }
        // console.log('은실종',newItems.value)

      }).catch(err => {
        console.error(err)
      })
    }

    return{
      session,
      banks,
      newItems,
      gradeItems,
      lrnerCdp,
      newYn,
      AbilityBankYn,
      ficjikmuItems,
      isMobile: navigationUtils.any()
    }
  }

}
</script>

<style scoped>

</style>