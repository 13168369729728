<template>
  <article v-if="isMobile"  class="content-section">
    <header class="section-header d-flex">
      <h4 class="title">은행실무종합과정  <span class="text text-muted">舊 직원실무평가</span></h4>
    </header>
    <!-- text-item-container -->
    <div class="text-item-container">
      <!-- text-item -->
      <template v-if="items.length > 0">
        <div v-for="(item, idx) in items" :key="idx" class="text-item">
          <div class="item-row">
            <span class="text-title">평가년도</span>
            <span class="text-description">{{ item.ended }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">성적</span>
            <span class="text-description">{{ item.paccept }}점</span>
          </div>
        </div>
      </template>
      <div v-else class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">데이터가 존재하지 않습니다.</p>
        </div>
      </div>
    </div>
  </article>

  <article v-else class="content-section section-spacing">
    <header class="section-header">
      <h4 class="title">은행실무종합과정  <span class="text text-muted">舊 직원실무평가</span></h4>
    </header>
    <div class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">평가년도</span></th>
          <th><span class="th-title">성적</span></th>
        </tr>
        </thead>
        <tbody>
          <tr v-if="items.length === 0">
            <td colspan="2"><div class="text-truncate text-center"><strong class="td-text">데이터가 존재하지 않습니다.</strong></div></td>
          </tr>
          <template v-else>
            <tr v-for="(item, idx) in items" :key="idx">
              <td><strong class="td-text">{{item.ended}}</strong></td>
              <td><strong class="td-text">{{item.paccept}}점</strong></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </article>
</template>

<script>

import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: "MyPageHistoryBank",
  props: {
    items: Array
  },
  setup(){
    const isMobile = navigationUtils.any();

    return{
      isMobile,
    }
  }
}
</script>

<style scoped>

</style>