<template>
  <article class="content-section section-spacing">
    <header class="section-header">
      <h4 class="title">자격증 이력</h4>
    </header>
    <div v-if="isMobile" class="text-item-container">
      <template v-if="pagedItems.length > 0">
        <div class="text-item" v-for="(item, index) in pagedItems" :key="index">
          <p class="item-subtitle">{{ item.pblcnInstNm }}</p>
          <h5 class="item-title">{{ item.lcsnNm }}</h5>
          <div class="item-row">
            <span class="text-title">자격증코드</span>
            <span class="text-description">{{ item.lcsnCd }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">자격적용일</span>
            <span class="text-description">{{ item.passYmd }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">등급</span>
            <span class="text-description">{{ item.grd1Cd ? item.grd1Cd : '-' }}</span>
          </div>
          <div class="item-row">
            <span class="text-title">지원금액</span>
            <span class="text-description">{{ item.sprtAmt > 0 ? numberComma(item.sprtAmt) : '-' }}</span>
          </div>
        </div>
      </template>
      <div v-else class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">등재된 자격증이 존재하지 않습니다.</p>
        </div>
      </div>
      <div style="margin-top: 64px">
        <CommonPaginationFront :paging="{
          pageNo: paging.pageNo,
          pageSize: paging.pageSize,
          totalCount: items.length
        }" :page-func="pagingFunc"/>
      </div>
    </div>
    <div v-else class="certificate-list-container">
      <div class="kb-table kb-table-bordered-v2">
        <table>
          <colgroup>
            <col style="width:auto">
            <col style="width:180px">
            <col style="width:140px">
            <col style="width:140px">
            <col style="width:100px">
            <col style="width:130px">
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">자격증명</span></th>
            <th><span class="th-title">주관처</span></th>
            <th><span class="th-title">자격증코드</span></th>
            <th><span class="th-title">자격적용일</span></th>
            <th><span class="th-title">등급</span></th>
            <th><span class="th-title">지원 금액</span></th>
          </tr>
          </thead>
          <tbody>
            <tr v-if="items.length === 0">
              <td colspan="6"><div class="text-truncate text-center"><strong class="td-text">등재된 자격증이 존재하지 않습니다.</strong></div></td>
            </tr>
            <template v-else>
              <tr v-for="(item, index) in pagedItems" :key="index">
                <td><div class="text-truncate"><strong class="td-text">{{ item.lcsnNm }}</strong></div></td>
                <td><span class="td-text">{{ item.pblcnInstNm }}</span></td>
                <td><span class="td-text">{{ item.lcsnCd }}</span></td>
                <td><span class="td-text">{{ item.passYmd }}</span></td>
                <td><span class="td-text">{{ item.grd1Cd ? item.grd1Cd : '-' }}</span></td>
                <td><span class="td-text">{{ item.sprtAmt > 0 ? numberComma(item.sprtAmt) : '-' }}</span></td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div style="margin-top: 64px">
        <CommonPaginationFront :paging="{
          pageNo: paging.pageNo,
          pageSize: paging.pageSize,
          totalCount: items.length
        }" :page-func="pagingFunc"/>
      </div>
    </div>
  </article>
</template>

<script>

import {numberComma, paginate, timestampToDateFormat} from '@/assets/js/util';
import {computed, reactive} from 'vue';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: "MyPageLicense",
  components: {CommonPaginationFront},
  props: {
    items: Array
  },
  setup(props) {
    const isMobile = navigationUtils.any();
    const paging = reactive({
      pageNo: 1,
      pageSize: 10
    });

    const pagedItems = computed(() => paginate(props.items
            .filter(x => x.passYmd !== '' && x.aplyHistIndctYn == 'N')
            .sort((a,b) => {if(a.lcsnNm < b.lcsnNm){ return -1} return 0;})
            .sort((a,b) => {if(a.passYmd > b.passYmd){ return -1} return 0;})
        , paging.pageNo, paging.pageSize));

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
    }


    return {
      paging,
      pagedItems,
      pagingFunc,
      timestampToDateFormat,
      numberComma,
      isMobile,
    }
  }
}
</script>
