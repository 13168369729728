<template>
  <nav class="mypage-tabs kb-nav-round-tabs">
    <ul class="kb-nav-list">
      <li v-for="(viewRoute, index) in viewRoutes" class="kb-nav-item" :key="index">
        <a href="javascript:" class="kb-nav-link" :class="{'is-active': $route.query.view === viewRoute.view}" @click="changeView(viewRoute.view)"><span class="kb-nav-text">{{viewRoute.name}}</span></a>
      </li>
    </ul>
  </nav>
</template>

<script>

// import {useStore} from 'vuex';
import {computed} from 'vue';
import {useRouter} from "vue-router";

export default {
  name: "MyPageRoute",
  props: {
    view: String
  },
  emits: ['changeView'],
  setup(props, {emit}) {
    // const store = useStore();
    const router = useRouter();

    const viewRoutes = computed(() => {
      const menu = [
        {name: '대시보드', view: 'dashboard'},
        {name: '연수이력', view: 'history'},
        {name: '마일리지', view: 'mileage'},
        {name: '직무인증제', view: 'certification'},
      ]
      // if(store.state.auth.session.jbgdCd && store.state.auth.session.jbgdCd.indexOf('L') === 0){
      //   menu.push({name: '직무인증제', view: 'certification'});
      // }
      return menu;
    });

    const changeView = (view) => {
      router.push({query: {view: view}});
      emit('changeView', view);
    }

    return {
      viewRoutes,
      changeView
    }
  }
}
</script>
