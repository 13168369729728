<template>
  <article class="content-section" :class="{'section-divider': !isMobile}">
    <header class="section-header">
      <h4 class="title">연수마일리지 상세현황</h4>
    </header>
    <div v-if="isMobile" class="text-item-container">
      <div class="text-item" v-for="(item, index) in finishItems" :key="index">
        <h5 class="item-title">{{ item.crseNm }}</h5>
        <div class="item-row">
          <span class="text-title">연수기간</span>
          <span class="text-description">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }}-{{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span>
        </div>
        <div class="item-row">
          <span class="text-title">마일리지</span>
          <span class="text-description">{{ item.exptMlg ? item.exptMlg : '-' }}</span>
        </div>
        <div class="item-row">
          <span class="text-title">취득마일리지</span>
          <span class="text-description" :class="{'text-primary': item.aplcnMlg}">{{ item.aplcnMlg ? item.aplcnMlg : '-' }}</span>
        </div>
        <div class="item-row">
          <span class="text-title">비고</span>
          <span class="text-description">{{ item.crseMlg ? '반영' : '수료' }}</span>
        </div>
      </div>
    </div>
    <div v-else class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
          <col style="width:auto">
          <col style="width:220px">
          <col style="width:132px">
          <col style="width:132px">
          <col style="width:280px">
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">과정명</span></th>
          <th><span class="th-title">연수기간</span></th>
          <th><span class="th-title">마일리지</span></th>
          <th><span class="th-title">취득 마일리지</span></th>
          <th><span class="th-title">비고</span></th>
        </tr>
        </thead>
        <tbody>
          <tr v-if="totalCount === 0">
            <td colspan="5"><div class="text-truncate text-center"><strong class="td-text">{{ currentYear }}년에 수료한 연수가 없습니다.</strong></div></td>
          </tr>
          <template v-else>
          <tr v-for="(item, index) in finishItems" :key="index">
            <td><div class="text-truncate"><strong class="td-text">{{ item.crseNm }}</strong></div></td>
            <td><span class="td-text">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }}-{{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span></td>
            <td><span class="td-text">{{ item.exptMlg ? item.exptMlg : '-' }}</span></td>
            <td><span class="td-text" :class="{'text-primary': item.aplcnMlg}">{{ item.aplcnMlg ? item.aplcnMlg : '-' }}</span></td>
            <td><span class="td-text">{{ item.aplcnMlg ? '반영' : '수료' }}</span></td>
          </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div style="margin-top: 64px">
      <CommonPaginationFront :paging="{
        pageNo: paging.pageNo,
        pageSize: paging.pageSize,
        totalCount: totalCount
      }" :page-func="pagingFunc"/>
    </div>
  </article>
</template>

<script>

import {computed, reactive} from 'vue';
import {paginate, timestampToDateFormat} from '@/assets/js/util';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: "MyPageMileageLearn",
  components: {CommonPaginationFront},
  props: {
    items: Array,
    currentYear: Number
  },
  setup(props) {
    const isMobile = navigationUtils.any();
    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
    });

    const totalCount = computed(() => props.items.length);
    const finishItems = computed(() => paginate(
        props.items, paging.pageNo, paging.pageSize)
    );

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
    }

    return {
      paging,
      finishItems,
      totalCount,
      pagingFunc,
      timestampToDateFormat,
      isMobile,
    }

  }
}
</script>
