<template>
  <article class="content-section section-divider">
    <header class="section-header">
      <h4 class="title">자격증 취득 현황 <span class="text text-muted">상품판매필수자격증</span></h4>
    </header>
    <div class="certificate-status-container">
      <template v-if="!isMobile">
        <!--  status-card-->
        <div v-for="(license, index) in salesLicenses" class="status-card" :class="[isGet(license.necessaries) && 'is-active']" :key="index">
          <div class="card-icon"><i class="icon-certificate-01"></i></div>
          <div class="card-content">
            <div class="content-title">{{ license.name }}</div>
          </div>
        </div>
        <!--  status-card-->
        <!--      <div class="status-card status-card-link">-->
        <!--        <a href="" class="card-link"></a>-->
        <!--        <div class="card-icon"><i class="icon-link"></i></div>-->
        <!--        <div class="card-content">-->
        <!--          <div class="content-title">전체</div>-->
        <!--        </div>-->
        <!--      </div>-->
      </template>
      <div v-else class="status-wrap">
        <!--  status-card-->
        <div v-for="(license, index) in salesLicenses" class="status-card" :class="[isGet(license.necessaries) && 'is-active']" :key="index">
          <div class="card-icon"><i class="icon-certificate-01"></i></div>
          <div class="card-content">
            <div class="content-title">{{ license.name }}</div>
          </div>
        </div>
        <!--  status-card-->
      </div>
    </div>
  </article>
</template>

<script>

import {computed} from 'vue';
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: "MyPageProductLicense",
  props: {
    items: Array
  },
  setup(props) {

    const licenseCodes = computed(() => props.items.map(x => x.lcsnCd));

    const salesLicenses = [
      {
        name: '펀드',
        necessaries: [
          ['WR2B', 'WR0X', 'WR2M', 'WR6Z', 'WR2Z'],
          ['WR7B', 'WR3D', 'WR2O', 'WR3O', 'WR6Z', 'WR2Z', 'WR7D', 'WR3B'],
          ['WR7C', 'WR3C', 'WR2N', 'WR6Z',  'WR2Z', 'WR7D', 'WR3B' ]
        ]
      },
      {
        name: '파생',
        necessaries: [
          ['WR7A', 'WR7G', 'WR3P', 'WR2X', 'WR2J', 'WR86', 'WR47', 'WR36']
        ]
      },
      {
        name: '생명',
        necessaries: [
          ['WR72', 'WR74']
        ]
      },
      {
        name: '손해',
        necessaries: [
          ['WR73', 'WR75']
        ]
      },
      {
        name: '제3',
        necessaries: [
          ['WR77', 'WR78']
        ]
      },
      {
        name: '변액',
        necessaries: [
          ['WR76', 'WR9D']
        ]
      }
    ];

    const isGet = (necessaries) => {
      let count = 0;
      for(let i = 0; i < necessaries.length; i++){
        // 내 자격증 중에서 있을 해당 데이터가 있을 경우
        for(let j = 0; j < licenseCodes.value.length; j++){
          if(necessaries[i].includes(licenseCodes.value[j])){
            count++;
            break;
          }
        }
      }
      return (necessaries.length === count);
    }

    return {
      salesLicenses,
      isGet,
      isMobile: navigationUtils.any()
    }
  }
}
</script>
