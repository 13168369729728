<template>
  <article class="content-section" :class="{'section-divider' : !isMobile}">
    <header class="section-header">
      <h4 class="title">배지</h4>
      <div v-if="!isMobile" class="header-sub">
        <router-link :to="{name:'BadgeBox'}" class="more-link text-dark">배지 보관함</router-link>
      </div>
    </header>
    <div v-if="items.length > 0" class="badge-list-container">
      <div class="badge-list-wrapper" v-if="isMobile">
        <ul class="badge-list" :class="{ 'kb-hrd-badge-list' : isMobile }">
          <!-- badge-item -->
          <!-- badge-item -->
          <li v-for="(item, index) in items" class="badge-item" :key="index">
            <IntegratedBadge
                :division="item.division"
                :badges="item"
                :badge-ty-cd-dcd="item.badgeTyCdDcd"
                :is-active="true"
            />
          </li>
          <!-- badge-item -->
        </ul>
      </div>
      <ul v-else class="badge-list" :class="{ 'kb-hrd-badge-list' : isMobile }">
        <!-- badge-item -->
        <!-- badge-item -->
        <li v-for="(item, index) in items" class="badge-item" :key="index">
          <IntegratedBadge
              :division="item.division"
              :badges="item"
              :badge-ty-cd-dcd="item.badgeTyCdDcd"
              :is-active="true"
          />
        </li>
        <!-- badge-item -->
      </ul>
    </div>
    <div v-else :class="{'lxp-layout': !isMobile, 'lxp-mobile-layout' : isMobile}">
      <div class="search-container">
        <div class="result-empty">
          <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
          <p class="text">획득한 배지가 없습니다.</p>
        </div>
      </div>
    </div>
    <div v-if="isMobile" class="section-buttons">
      <button class="kb-btn-content-item" @click="clickAll">배지 보관함</button>
    </div>
  </article>
</template>

<script>

import IntegratedBadge from '@/components/common/IntegratedBadge';
import navigationUtils from '@/assets/js/navigationUtils';
import {useRouter} from 'vue-router';

export default {
  name: "MyPageBadge",
  components: {IntegratedBadge},
  props: {
    items: Array
  },
  setup() {
    const router = useRouter();

    const clickAll = () => {
      router.push({name:'BadgeBox'});
    }

    return {
      clickAll,
      isMobile: navigationUtils.any()
    }

  }
}
</script>
