<template>
  <MyPageLearn :items="learns" :effected="effected" @lrnHistoryPrint:lrnObjPrint="lrnObjPrint"/>
  <MyPageLicense :items="licenses"/>
  <!-- 은실종 -->
  <MyPageHistoryBank :items="banks"/>
  <!-- 외국어, IT 평가 -->
  <MyPageEval :items="evaluations"/>
  <MyPageGlobal :items="globals"/>


</template>

<script>
import MyPageLearn from '@/components/mypage/history/MyPageLearn';
import MyPageLicense from '@/components/mypage/history/MyPageLicense';
import MyPageHistoryBank from '@/components/mypage/history/MyPageHistoryBank';
import navigationUtils from '@/assets/js/navigationUtils';
import MyPageGlobal from "@/components/mypage/history/MyPageGlobal";
import MyPageEval from "@/components/mypage/history/MyPageEval.vue";

export default {
  name: "MyPageHistory",
  components: {MyPageEval, MyPageGlobal, MyPageLicense, MyPageLearn, MyPageHistoryBank},
  props: {
    learns: Array,
    licenses: Array,
    banks: Array,
    globals: Array,
    evaluations: Array,
    effected: Number
  },
  emits: ['lrnHistoryPrint:lrnObjPrint'],
  setup(props, {emit}) {
    const isMobile = navigationUtils.any();

    const lrnObjPrint = (data) => {
      emit('lrnHistoryPrint:lrnObjPrint', data);
    }

    return {
      isMobile,
      lrnObjPrint,
    }
  }
}
</script>
